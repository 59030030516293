import { NavItem } from "@/types/Navigation";
import ProductLists from "@/components/Navigation/LearnContent/Product/Lists";
import LinkList from "@/components/Navigation/LearnContent/LinkList";
import LinkLists from "@/components/Navigation/LearnContent/LinkLists";
import Hero from "@/components/Navigation/LearnContent/Hero";
import ProductResources from "@/components/Navigation/LearnContent/Product/Resources";
import ProductSummaries from "@/components/Navigation/LearnContent/Product/Summaries";

type Props = {
  selectedContent: NavItem | null;
};
export default function LearnContent({ selectedContent }: Props) {
  if (!selectedContent) return null;
  return (
    <>
      <Hero hero={selectedContent.hero} location="nav-dropdown" />
      <div className="p-8 pb-16 mb-2 flex flex-col gap-8">
        <ProductSummaries productSummaries={selectedContent.productSummaries} />
        <ProductResources productResources={selectedContent.productResources} />
        <ProductLists
          label={selectedContent.label}
          productLists={selectedContent.productLists}
        />
        <LinkList linkList={selectedContent.linkList} />
        <LinkLists linkLists={selectedContent.linkLists} />
      </div>
    </>
  );
}
