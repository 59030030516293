"use client";

import { NavItem, NavSection } from "@/types/Navigation";
import NavLinksSection from "./Section";
import { cn } from "@/lib/utils";

type Props = {
  sections: NavSection[];
  setSelectedContent: React.Dispatch<React.SetStateAction<NavItem | null>>;
  selectedContent: NavItem | null;
  className?: string;
  triggerOnHover?: boolean;
};

export default function NavLinks({
  sections,
  setSelectedContent,
  selectedContent,
  className,
  triggerOnHover = false,
}: Props) {
  if (!sections || sections.length === 0) return null;
  return (
    <div className={cn("py-2 min-w-[270px]", className)}>
      {sections.map((section) => (
        <NavLinksSection
          key={`sidebar-item-${section.title}`}
          section={section}
          setSelectedContent={setSelectedContent}
          selectedContent={selectedContent}
          triggerOnHover={triggerOnHover}
        />
      ))}
    </div>
  );
}
