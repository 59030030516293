import Image from "next/image";

import { KeyboardArrowDown } from "@/components/icons";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdownMenu";

interface UserMenuProps {
  headerUrls?: {
    logout: string;
    accountSettings: string;
    learnerProfile: string;
    resumeBlock: string;
  };
  userImage?: string;
}

function UserMenuItem({ href, label }: { href: string; label: string }) {
  return (
    <DropdownMenuItem asChild>
      <a
        href={href}
        className="not-prose inline-block w-full text-base text-primary py-2 px-4 hover:bg-putty-200"
      >
        {label}
      </a>
    </DropdownMenuItem>
  );
}

export default function UserMenu({ headerUrls, userImage }: UserMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="text-medium">
        <Image
          src={userImage || ""}
          alt=""
          height={36}
          width={36}
          className="rounded-full mr-2"
        />
        <KeyboardArrowDown svgProps={{ className: "flex-none" }} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-80">
        {headerUrls?.resumeBlock && (
          <UserMenuItem
            href={headerUrls.resumeBlock}
            label="Resume your last course"
          />
        )}
        <UserMenuItem href="https://home.edx.org" label="Dashboard" />
        <UserMenuItem href="https://careers.edx.org" label="Career" />
        {headerUrls?.learnerProfile && (
          <UserMenuItem href={headerUrls.learnerProfile} label="Profile" />
        )}
        {headerUrls?.accountSettings && (
          <UserMenuItem href={headerUrls.accountSettings} label="Account" />
        )}
        <UserMenuItem href="https://courses.edx.org/logout" label="Sign Out" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
