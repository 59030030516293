import { NavItem, NavSection } from "@/types/Navigation";
import NavLinksLink from "@/components/Navigation/NavLinks/Link";

type Props = {
  section: NavSection;
  setSelectedContent: React.Dispatch<React.SetStateAction<NavItem | null>>;
  selectedContent: NavItem | null;
  triggerOnHover?: boolean;
};

export default function NavLinksSection({
  section,
  setSelectedContent,
  selectedContent,
  triggerOnHover = false,
}: Props) {
  const { title, subTitle, items } = section;
  return (
    <div
      className={
        title
          ? subTitle
            ? "py-4"
            : "py-2"
          : "py-3 border-t-2 border-b-2 border-gray-300"
      }
    >
      {title && (
        <div className="w-full mb-2 mt-0 px-6 text-base font-bold">{title}</div>
      )}
      {subTitle && (
        <div className="px-6 text-sm text-black mb-4">{subTitle}</div>
      )}
      {items && (
        <ul role="list">
          {items.map((item) => (
            <li role="listitem" key={`sidebar-link-${item.label}`}>
              <NavLinksLink
                item={item}
                setSelectedContent={setSelectedContent}
                selectedContent={selectedContent}
                triggerOnHover={triggerOnHover}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
