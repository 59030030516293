import { CTA } from "@/types";

import { cn } from "@/lib/utils";

type Props = {
  cta?: CTA | null;
  className?: string | null;
};
export default function CTALink({ cta, className }: Props) {
  if (!cta) return null;
  return (
    <a
      href={cta.url}
      className={cn(
        "inline-block cursor-pointer text-link mb-1 hover:underline",
        className,
      )}
    >
      {cta.label}
    </a>
  );
}
