"use client";
import { useState } from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { Nav } from "@/types/Navigation";
import Modal from "./Modal";
import { ActiveBanner } from "./types";
import { EventCacheType, resetEventCache } from "@/utils/tagular/helpers";
import { ProcessedCourse } from "@/lib/course/types";
type Props = {
  navigation: Nav;
  activeBanner?: ActiveBanner;
  course?: ProcessedCourse;
};

export default function Navigation({
  navigation,
  activeBanner,
  course,
}: Props) {
  const [openNav, setOpenNavState] = useState(false);

  const setOpenNav = (value: boolean) => {
    if (value) {
      resetEventCache(EventCacheType.MegaNav);
    }
    setOpenNavState(value);
  };
  return (
    <>
      <header className="contents mx-auto border-b shadow border-putty-light h-[76px] pointer-events-auto">
        <DesktopNav
          course={course}
          navigation={navigation}
          openNav={openNav}
          setOpenNav={setOpenNav}
          activeBanner={activeBanner}
        />
        <MobileNav
          navigation={navigation}
          openNav={openNav}
          setOpenNav={setOpenNav}
          activeBanner={activeBanner}
        />
      </header>
      <Modal open={openNav} onOpenChange={setOpenNav} navigation={navigation} />
    </>
  );
}
