"use client";
import { useLocale } from "next-intl";
import useTranslations from "@/i18n/useTranslations";
import { locales } from "@/i18n/config";
import { useParams } from "next/navigation";
import { ChangeEvent, useTransition, useState } from "react";
import { usePathname, useRouter } from "@/i18n/routing";
import { Button } from "@/components/ui/button";
import setLocaleCookie from "@/lib/locale/setLocaleCookie";

export default function LocaleSwitcher() {
  const t = useTranslations("LocaleSwitcher");
  const locale = useLocale();
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();
  const params = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState(locale);

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value;
    setSelectedLanguage(newLocale);
  };
  const onButtonClick = () => {
    // Setting cookie for Prospectus => Not needed for RV site
    setLocaleCookie(selectedLanguage);
    startTransition(() => {
      router.replace(
        // @ts-expect-error -- TypeScript will validate that only known `params`
        // are used in combination with a given `pathname`. Since the two will
        // always match for the current route, we can skip runtime checks.
        { pathname, params },
        { locale: selectedLanguage },
      );
    });
  };
  return (
    <div className="min-w-64">
      <label
        htmlFor="languageSelector"
        className="text-base text-left mb-2 mt-0"
      >
        Choose your language
      </label>
      <div className="mt-4 flex items-center">
        <select
          id="languageSelector"
          className="text-secondary bg-white text-base border border-gray rounded py-2 px-4 mr-2 w-full focus:border-primary"
          value={selectedLanguage}
          disabled={isPending}
          onChange={onSelectChange}
        >
          {locales.map((cur) => (
            <option key={cur} value={cur}>
              {t(cur)}
            </option>
          ))}
        </select>
        <Button
          className="border-2 border-primary-foreground"
          size="lg"
          disabled={isPending}
          onClick={onButtonClick}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}
