import { Nav, NavItem } from "@/types/Navigation";
import { Cross2Icon } from "@radix-ui/react-icons";
import LearnContent from "@/components/Navigation/LearnContent";
import NavLinks from "@/components/Navigation/NavLinks";
import { Close } from "@radix-ui/react-dialog";

type Props = {
  navigation: Nav;
  setSelectedContent: React.Dispatch<React.SetStateAction<NavItem | null>>;
  selectedContent: NavItem | null;
};

export default function DesktopLinks({
  navigation,
  selectedContent,
  setSelectedContent,
}: Props) {
  return (
    <>
      <NavLinks
        sections={navigation.sections}
        selectedContent={selectedContent}
        setSelectedContent={setSelectedContent}
        className="bg-putty-100 py-2 min-w-[270px] overflow-auto pb-[76px]"
        triggerOnHover={true}
      />
      {selectedContent && (
        <div className="flex flex-col text-primary text-left bg-white w-[calc(100vw-270px)] xl:w-[1020px] overflow-auto pb-6">
          <LearnContent selectedContent={selectedContent} />
          <div>
            <Close asChild>
              <button
                className="absolute overflow-visible right-4 top-4 rounded-full hover:bg-primary p-2 text-white bg-secondary/30"
                role="button"
                aria-label="Close navigation panel"
              >
                <Cross2Icon className="h-6 w-6" />
              </button>
            </Close>
          </div>
        </div>
      )}
    </>
  );
}
