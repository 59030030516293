import { useState, useLayoutEffect, createRef } from "react";

import { LinkList as LinkListType } from "@/types/Navigation";

type Props = {
  linkList?: LinkListType | null;
};

export default function LinkList({ linkList }: Props) {
  const ref = createRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState<Boolean>(false);
  const [showMore, setShowMore] = useState<Boolean>(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  if (!linkList) return null;

  return (
    <div>
      <div className="mt-0 text-primary mb-3 text-base font-bold">
        {linkList.title}
      </div>
      <div
        className={`flex flex-wrap gap-2 ${
          showMore ? "" : "max-h-20 overflow-hidden"
        }`}
        ref={ref}
      >
        {linkList.links?.map((topic) => (
          <a
            key={topic.label}
            href={topic.url}
            className="text-primary border rounded border-light-gray text-xs leading-6 text-center py-0.5 px-2 hover:bg-primary hover:text-white hover:no-underline"
          >
            {topic.label}
          </a>
        ))}
      </div>
      {isOverflow && (
        <button
          className="text-link py-2 mt-4 hover:text-link-hover"
          onClick={() => setShowMore(true)}
        >
          Show more popular topics
        </button>
      )}
      {showMore && (
        <button
          className="text-link py-2 mt-4 hover:text-link-hover"
          onClick={() => setShowMore(false)}
        >
          Show less popular topics
        </button>
      )}
    </div>
  );
}
