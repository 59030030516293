import { ChevronRightIcon } from "@radix-ui/react-icons";

import { NavItem } from "@/types/Navigation";
import { Button } from "@/components/ui/button";

type Props = {
  item: NavItem;
  setSelectedContent: React.Dispatch<React.SetStateAction<NavItem | null>>;
  selectedContent: NavItem | null;
  triggerOnHover?: boolean;
};

export default function NavLinksLink({
  item,
  setSelectedContent,
  selectedContent,
  triggerOnHover = false,
}: Props) {
  if (!item) return null;

  if (item.url) {
    return (
      <li
        onMouseOver={() => {
          if (triggerOnHover) setSelectedContent(null);
        }}
      >
        <a
          href={item.url}
          className="flex text-black w-full px-6 py-2 text-sm hover:bg-putty-200 hover:no-underline hover:text-black"
        >
          {item.label}
        </a>
      </li>
    );
  }
  if (item.cta) {
    return (
      <Button variant="primaryOutline" asChild key={item.cta?.label}>
        <a
          href={item.cta?.url || "/"}
          className="text-center text-base text-primary border-2 border-black border-solid hover:text-white hover:bg-primary mx-6 py-2 px-4 hover:no-underline"
        >
          {item.cta?.label}
        </a>
      </Button>
    );
  }
  return (
    <li key={item.label}>
      <button
        className={
          item.label === selectedContent?.label
            ? "flex w-full items-center justify-between text-sm px-6 py-2 leading-6 bg-putty-200"
            : "flex w-full items-center justify-between text-sm px-6 py-2 leading-6 text-black hover:bg-putty-200"
        }
        onClick={() => setSelectedContent(item)}
        onMouseEnter={() => {
          if (triggerOnHover) setSelectedContent(item);
        }}
      >
        {item.label}
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </li>
  );
}
