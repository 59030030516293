"use client";

import { OtBannerLinkProps } from "./types";

export default function OtBannerLink({
  text,
  href,
  className,
}: OtBannerLinkProps) {
  const toggleConsentModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (window.OneTrust) {
      window.OneTrust.ToggleInfoDisplay();
    }
  };
  return (
    <a
      className={className}
      href={href}
      onClick={(e) => {
        toggleConsentModal(e);
      }}
    >
      {text}
    </a>
  );
}
