import { CTA } from "@/types";
import CTALink from "@/components/Navigation/LearnContent/ctaLink";

type Props = {
  links?: CTA[] | null;
};

export default function Links({ links }: Props) {
  if (!links || links.length === 0) return null;
  return (
    <ul>
      {links.map((link) => (
        <li key={link.label}>
          <CTALink cta={link} />
        </li>
      ))}
    </ul>
  );
}
