import { CheckIcon } from "@radix-ui/react-icons";

type Props = {
  features?: string[] | null;
};
export default function Features({ features }: Props) {
  if (!features || features.length === 0) return null;
  return (
    <ul className="mb-4">
      {features.map((feature) => (
        <li key={feature} className="flex my-1 items-center gap-2">
          <div className="bg-primary rounded-full">
            <CheckIcon className="h-4 w-4 text-white" />
          </div>
          <p className="text-primary text-sm m-0">{feature}</p>
        </li>
      ))}
    </ul>
  );
}
