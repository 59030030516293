"use client";

import Cookies from "js-cookie";
import { usePathname } from "next/navigation";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Close } from "@/components/icons";
import { Button } from "@/components/ui/button";

type SitewideBannerClientProps = {
  content?: ReactNode;
  contentEs?: ReactNode;
  campaignId?: string;
  campaignIdEs?: string;
  backgroundColor?: string;
  backgroundColorEs?: string;
};

function SitewideBannerContent({
  content,
  campaignId,
  backgroundColor = "mint",
}: SitewideBannerClientProps) {
  const cookieName = `edx-sitewide-banner-${campaignId}`;
  const [showBanner, setShowBanner] = useState(false);

  const setHasViewedBannerCookie = useCallback(() => {
    Cookies.set(cookieName, "true", { expires: 365 });
  }, [cookieName]);

  const hideBanner = useCallback(() => {
    setShowBanner(false);
    setHasViewedBannerCookie();
  }, [setHasViewedBannerCookie]);

  useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setShowBanner(true);
    }
  }, [cookieName, setShowBanner]);

  const colorSchemes: { [key: string]: string } = {
    brand: "bg-brand text-brand-foreground",
    pink: "bg-accent-pink text-accent-foreground",
    mint: "bg-accent-mint text-accent-foreground",
    blue: "bg-accent-blue text-accent-foreground",
    yellow: "bg-accent-yellow text-accent-foreground",
  };

  return (
    <div
      role="alert"
      className={`relative flex justify-center items-center text-sm py-1 pl-2 pr-10 ${colorSchemes[backgroundColor]} ${!showBanner ? "hidden" : ""}`}
    >
      <p className="text-center text-inherit text-sm m-0">{content}</p>
      <Button
        variant="ghost"
        className="absolute top-auto right-0 text-inherit hover:bg-transparent"
        onClick={hideBanner}
      >
        <Close />
        <span className="sr-only">Close site banner.</span>
      </Button>
    </div>
  );
}

function SitewideBanner({
  content,
  contentEs,
  campaignId,
  campaignIdEs,
  backgroundColor,
  backgroundColorEs,
}: SitewideBannerClientProps) {
  const pathname = usePathname();
  const languageCode = pathname?.startsWith("/es") ? "es" : "en";
  switch (languageCode) {
    case "es":
      if (campaignIdEs && contentEs) {
        return (
          <SitewideBannerContent
            content={contentEs}
            campaignId={campaignIdEs}
            backgroundColor={backgroundColorEs}
          />
        );
      }
      return null;
    case "en":
    default:
      if (campaignId && content) {
        return (
          <SitewideBannerContent
            content={content}
            campaignId={campaignId}
            backgroundColor={backgroundColor}
          />
        );
      }
      return null;
  }
}

export default SitewideBanner;
