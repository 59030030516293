"use client";

import LocaleSwitcher from "@/components/Footer/LocaleSwitcher";
import { FooterSecondaryLinks, FooterSectionLinks } from "@/types/Footer";
import { CTA } from "@/types";
import OtBannerLink from "../OtBannerLink";

type Props = {
  links?: FooterSecondaryLinks;
};
export default function SecondaryLinks({ links }: Props) {
  if (!links) return null;
  return (
    <div className="flex flex-wrap justify-between">
      <div className="mr-12 mb-6">{getSecondaryLinks(links.edx)}</div>
      <div className="mr-12 mb-6">{getSecondaryLinks(links.connect)}</div>
      <div className="mb-6 grow">{getSecondaryLinks(links.legal)}</div>
      <div className="mb-6 flex">
        <LocaleSwitcher />
      </div>
    </div>
  );
}

const getSecondaryLinks = (linkSection: FooterSectionLinks) => {
  linkSection.links = linkSection.links.filter(
    (link: CTA) => !link.url.includes("boot-camps"),
  );
  return (
    <>
      <span className="text-base text-left pb-6 mt-0 font-bold">
        {linkSection.title}
      </span>
      <ul className="my-6 list-none">
        {linkSection.links.map((link: CTA) => (
          <li key={link.label} className="mb-2 hover:underline text-base">
            {link.label === "Your Privacy Choices" ? (
              <OtBannerLink
                href={link.url}
                text={link.label}
                className="text-white hover:text-white"
              />
            ) : (
              <a className="text-white hover:text-white" href={link.url}>
                {link.label}
              </a>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
