"use client";
import { ProductSummary } from "@/types/Navigation";
import CTALink from "@/components/Navigation/LearnContent/ctaLink";
import Description from "./ui/Description";
import Links from "./ui/Links";
import Features from "./ui/Features";
type Props = {
  productSummaries?: ProductSummary[] | null;
};

export default function ProductSummaries({ productSummaries }: Props) {
  if (!productSummaries || productSummaries.length === 0) return null;
  return (
    <div className="lg:flex lg:flex-wrap lg:-mx-4">
      {productSummaries.map((product, index) => (
        <div
          key={product.title}
          className={`py-4 px-0 lg:py-0 lg:px-4 max-w-full basis-0 grow ${
            index > 0 ? "border-t lg:border-l lg:border-t-0" : ""
          }`}
        >
          <div className="mt-0 mb-2 text-base text-primary font-bold">
            {product.title}
          </div>
          <Description description={product.description} />
          <Features features={product.features} />
          <Links links={product.links} />
          <CTALink cta={product.cta} className="my-4" />
        </div>
      ))}
    </div>
  );
}
