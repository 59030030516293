import { Nav, NavItem } from "@/types/Navigation";

import { ChevronLeftIcon } from "@radix-ui/react-icons";
import LearnContent from "@/components/Navigation/LearnContent";
import NavLinks from "@/components/Navigation/NavLinks";
import { Button } from "@/components/ui/button";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";

type Props = {
  navigation: Nav;
  setSelectedContent: React.Dispatch<React.SetStateAction<NavItem | null>>;
  selectedContent: NavItem | null;
};

export default function MobileLinks({
  navigation,
  selectedContent,
  setSelectedContent,
}: Props) {
  const { header } = navigation;
  const userLoggedIn = isUserLoggedIn();
  return (
    <div className="w-full">
      <div className="fixed w-full bg-white z-[999]">
        <div className="flex p-4 border-b justify-between gap-4 items-center">
          {userLoggedIn ? (
            <Button variant="default" className="w-full text-base py-5" asChild>
              <a href="https://home.edx.org">Dashboard</a>
            </Button>
          ) : (
            <>
              <Button
                variant="brandOutline"
                className="w-1/2 text-base py-5"
                asChild
              >
                <a href={header.cta?.signIn?.url}>
                  {header.cta?.signIn?.label}
                </a>
              </Button>
              <Button variant="brand" className="w-1/2 text-base py-5" asChild>
                <a href={header.cta?.register?.url}>
                  {header.cta?.register?.label}
                </a>
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="pt-20">
        {selectedContent ? (
          <Content
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
          />
        ) : (
          <NavLinks
            sections={navigation.sections}
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
            className="pb-24"
          />
        )}
      </div>
    </div>
  );
}

type ContentProps = {
  selectedContent: NavItem | null;
  setSelectedContent: React.Dispatch<React.SetStateAction<NavItem | null>>;
};

function Content({ selectedContent, setSelectedContent }: ContentProps) {
  if (!selectedContent) return null;
  return (
    <div className="w-full">
      <button
        className="flex items-center min-h-12 px-4 gap-1"
        onClick={() => setSelectedContent(null)}
      >
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        Back to menu
      </button>
      <div className="flex flex-col text-primary text-left bg-white overflow-auto pb-6">
        <LearnContent selectedContent={selectedContent} />
      </div>
    </div>
  );
}
