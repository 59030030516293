import { useTranslations as useNextIntlTranslations } from "next-intl";

export type Messages = {
  Upgrade: {
    title: string;
    description: string;
    ctaHeader: string;
  };
  LocaleSwitcher: {
    label: string;
    en: string;
    es: string;
  };
};

type UseTranslationsReturn<T extends keyof Messages> = (
  key: keyof Messages[T],
) => any;

const useTranslations = <T extends keyof Messages>(
  namespace: T,
): UseTranslationsReturn<T> => {
  return useNextIntlTranslations<keyof Messages>(namespace);
};

export default useTranslations;
