import Cookies from "js-cookie";
import logger from "@/lib/logger";

/*
example cookie:

"{\"version\": 1\054 \"username\": \"example-username\"\054 \"email\": \"example@email.com\"\054 \"header_urls\": {\"logout\": \"https://courses.edx.org/logout\"\054 \"account_settings\": \"https://courses.edx.org/account/settings\"\054 \"learner_profile\": \"https://courses.edx.org/u/example-username\"\054 \"resume_block\": \"https://courses.edx.org/courses/etc\"}\054 \"user_image_urls\": {\"full\": \"https://test.com/example.jpg\"\054 \"large\": \"https://test.com/example.jpg\"\054 \"medium\": \"https://test.com/example.jpg\"\054 \"small\": \"https://test.com/example.jpg\"}}"

*/

interface RawUserInfo {
  username: string;
  email: string;
  header_urls: {
    logout: string;
    account_settings: string;
    learner_profile: string;
    resume_block: string;
  };
  user_image_urls: {
    full: string;
    large: string;
    medium: string;
    small: string;
  };
}

const transformUserInfoCookie = (rawCookie: string): RawUserInfo | null => {
  try {
    // Fix any encoded commas/quotes that have been double escaped before parsing.
    return JSON.parse(rawCookie.replace(/\\054/g, ",").replace(/\\"/g, '"'));
  } catch (e) {
    logger.warn("Failed to parse user info cookie", e);
    return null;
  }
};

interface UserInfo {
  username: string;
  email: string;
  headerUrls?: {
    logout: string;
    accountSettings: string;
    learnerProfile: string;
    resumeBlock: string;
  };
  userImageUrls?: {
    full: string;
    large: string;
    medium: string;
    small: string;
  };
}

const getUserInfo = (): UserInfo | null => {
  const rawCookie = Cookies.get("prod-edx-user-info");
  if (!rawCookie) {
    return null;
  }
  const cookieData = transformUserInfoCookie(rawCookie);
  if (cookieData) {
    const userInfo: UserInfo = {
      username: cookieData.username,
      email: cookieData.email,
    };
    if (cookieData.header_urls) {
      userInfo.headerUrls = {
        logout: cookieData.header_urls.logout,
        accountSettings: cookieData.header_urls.account_settings,
        learnerProfile: cookieData.header_urls.learner_profile,
        resumeBlock: cookieData.header_urls.resume_block,
      };
    }
    if (cookieData.user_image_urls) {
      userInfo.userImageUrls = {
        full: cookieData.user_image_urls.full,
        large: cookieData.user_image_urls.large,
        medium: cookieData.user_image_urls.medium,
        small: cookieData.user_image_urls.small,
      };
    }
    return userInfo;
  }
  return null;
};

export default getUserInfo;
