"use client";
import { useState } from "react";
import Image from "next/image";

import {
  HamburgerMenuIcon,
  MagnifyingGlassIcon,
  Cross1Icon,
} from "@radix-ui/react-icons";
import { Nav } from "@/types/Navigation";
import ProductSearch from "@/components/ProductSearch";
import { ActiveBanner } from "../types";
import SitewideBanner from "@/components/SitewideBanner";

type Props = {
  navigation: Nav;
  openNav: boolean;
  setOpenNav: Function;
  activeBanner?: ActiveBanner;
};

export default function MobileNav({
  navigation,
  openNav,
  setOpenNav,
  activeBanner,
}: Props) {
  const { header, sections } = navigation;
  const [showSearch, setShowSearch] = useState(false);

  return (
    <div className="grid grid-cols-3 md:hidden relative h-[76px] justify-center items-center">
      <div className="pl-4">
        <button
          className="hover:bg-primary p-2 rounded-full hover:text-white"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <Cross1Icon height={20} width={20} />
          ) : (
            <HamburgerMenuIcon height={20} width={20} />
          )}
        </button>
      </div>
      <div className="flex justify-items-center justify-center">
        <a href="/">
          <Image
            height={60}
            width={60}
            src={header.logo?.url}
            alt={header.logo?.altText || "edX"}
          />
        </a>
      </div>
      <div className="pr-4 flex justify-items-end justify-end">
        <button
          className="hover:bg-primary p-2 rounded-full hover:text-white focus:border-gray-dark"
          onClick={() => setShowSearch(!showSearch)}
        >
          {showSearch ? (
            <Cross1Icon height={24} width={24} />
          ) : (
            <MagnifyingGlassIcon height={24} width={24} />
          )}
        </button>
      </div>
      {showSearch && (
        <div className="bg-light absolute w-full -bottom-12 z-50">
          <ProductSearch
            variant="nav-header"
            placeholder="Search online courses"
            embedded={false}
          />
        </div>
      )}
      <div className="absolute top-full w-full z-40">
        <SitewideBanner {...activeBanner} />
      </div>
    </div>
  );
}
